import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Retailer, RetailerInput, ResultRetailers } from 'src/types/retailers';
import Axios from 'axios';

interface RetailersState {
    data: ResultRetailers;
    dateRange: number[];
    affiliateNetworks: any[];
};

const startTo = new Date().getTime();

const initialState: RetailersState = {
    data: null,
    dateRange: [null, startTo],
    affiliateNetworks: [],
};

const slice = createSlice({
    name: 'retailers',
    initialState,
    reducers: {
        getRetailers(state: RetailersState, action: PayloadAction<ResultRetailers>) {
            state.data = action.payload;
        },
        getAffiliateNetworks(state: RetailersState, action: PayloadAction<ResultRetailers>) {
            state.affiliateNetworks = action.payload;
        },
        setDateRange(state: RetailersState, action: PayloadAction<any[]>) {
            state.dateRange = action.payload;
        }
    }
});

export const getStatusName = (status: number): string => {
    switch (status) {
        case 1:
            return 'Request Sent';

        case 2:
            return 'Approved';

        case 3:
            return 'Rejected';

        case 4:
            return 'Postponed';

        default:
            return '';
    }
}

export const getStatusColors = (status: number): string => {
    switch (status) {
        case 1:
            return '#d1d100';

        case 2:
            return '#00eb00';

        case 3:
            return '#fb5d5d';

        case 4:
            return '#6a6a6a';

        default:
            return '';
    }
}

export const reducer = slice.reducer;

export const getRetailersData = (): AppThunk => async (dispatch) => {
    const response = await Axios
        .get<any, { data: ResultRetailers }>('https://api.wishr-click.com/api/retailers', {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
            },
        });

    dispatch(slice.actions.getRetailers(response.data));
};

export const getAffiliateNetworksData = (): AppThunk => async (dispatch) => {
    const response = await Axios
        .get<any, any>('https://api.wishr-click.com/api/affiliate_networks', {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
            },
        });

    dispatch(slice.actions.getAffiliateNetworks(response.data));
};

export const setRetailersDateRange = (range: any[]): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setDateRange(range));
};

export const mutateRetailers = (body: RetailerInput): AppThunk => async (dispatch) => {
    body.status = Number(body.status);
    body.affiliate_network = Number(body.affiliate_network);

    if (body.affiliate_network === 999999 && body.new_affiliate_network) {
        body.affiliate_network = body.new_affiliate_network;
    }

    delete body.new_affiliate_network;

    await Axios
        .put<any, any>('https://api.wishr-click.com/api/retailers', body, {
            headers: {
                'Authorization': localStorage.getItem('accessToken'),
            },
        }).then(response => {
            dispatch(getRetailersData());
        }).catch(err => {
            console.error(new Error(err));
        });
};

export const selectRetailers = (state: { retailers: { data: ResultRetailers } }) => state.retailers.data;
export const selectRetailersDateRange = (state: { retailers: { dateRange: number[]; }; }) => state.retailers.dateRange;
export const selectAffiliateNetworks = (state: { retailers: { affiliateNetworks: any; }; }) => state.retailers.affiliateNetworks;

export default slice;
