import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Proxy, ProxyInput, ResultProxy } from 'src/types/proxy';
import Axios from 'axios';

interface ProxyState {
    data: ResultProxy;
    error: any;
};

const initialState: ProxyState = {
    data: null,
    error: null
};

const slice = createSlice({
    name: 'proxy',
    initialState,
    reducers: {
        getProxy(state: ProxyState, action: PayloadAction<ResultProxy>) {
            state.data = action.payload;
        },
        errorProxy(state: ProxyState, action: PayloadAction<any>) {
            state.error = action.payload;
        }
    }
});

export const reducer = slice.reducer;

export const getProxyData = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.errorProxy(null));

    const response = await Axios.get<any, { data: ResultProxy }>('https://api.wishr-click.com/api/proxy', {
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    });

    dispatch(slice.actions.getProxy(transformData(response.data)));
};

export const mutateProxy = (body: ProxyInput): AppThunk => async (dispatch) => {
    dispatch(slice.actions.errorProxy(null));

    await Axios.post<any, any>('https://api.wishr-click.com/api/proxy', body, {
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    })
        .then(response => {
            
            dispatch(getProxyData());
        }).catch(err => {
            
            dispatch(slice.actions.errorProxy(err.response.data));
        });
};

export const deleteProxy = (id: number): AppThunk => async (dispatch) => {
    dispatch(slice.actions.errorProxy(null));

    await Axios.delete('https://api.wishr-click.com/api/proxy', {
        params: { id },
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    });

    dispatch(getProxyData());
};



function transformData(data: ResultProxy): ResultProxy {
    const result: any = data;

    return result;
}

export const selectProxy = (state: { proxy: { data: ResultProxy; }; }) => state.proxy.data;
export const selectErrorProxy = (state: { proxy: { error: any; }; }) => state.proxy.error;

export default slice;
