import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Feed, ResultFeeds } from 'src/types/feeds';
import Axios from 'axios';

interface FeedsState {
    data: ResultFeeds;
    dateRange: number[];
    item: Feed;
    rules: any,
};

const startTo = new Date().getTime();
const headers = {
    'Authorization': 'Bearer OW4OB6WSx0ir5Giw8_8WKg',
};

const initialState: FeedsState = {
    data: [
        // {
        //     priority: 1,
        //     dateupdate: '2023-02-01',
        //     id: 1,
        //     name: 'dasdasd',
        //     notes: 'note text React components for faster and easier web development. Build your own design system, or start with Material Design.',
        //     status: 'draft',
        //     amountpages: 5,
        // }
    ],
    dateRange: [null, startTo],
    item: null,
    rules: null,
};

const slice = createSlice({
    name: 'feeds',
    initialState,
    reducers: {
        getFeeds(state: FeedsState, action: PayloadAction<ResultFeeds>) {
            state.data = action.payload;
        },
        getFeedsItem(state: FeedsState, action: PayloadAction<Feed>) {
            state.item = action.payload;
        },
        setDateRange(state: FeedsState, action: PayloadAction<any[]>) {
            state.dateRange = action.payload;
        },
        getFeedsRules(state: FeedsState, action: PayloadAction<any>) {
            state.rules = action.payload;
        },
    }
});

export const getStatusName = (status: number): string => {
    switch (status) {
        case 1:
            return 'Request Sent';

        case 2:
            return 'Approved';

        case 3:
            return 'Rejected';

        case 4:
            return 'Postponed';

        default:
            return '';
    }
}

export const getStatusColors = (status: number): string => {
    switch (status) {
        case 1:
            return '#d1d100';

        case 2:
            return '#00eb00';

        case 3:
            return '#fb5d5d';

        case 4:
            return '#6a6a6a';

        default:
            return '';
    }
}

export const reducer = slice.reducer;

export const getFeedsData = (): AppThunk => async (dispatch) => {
    try {
        const response = await Axios
            .get<any, { data: ResultFeeds }>('https://feeds.wishr-click.com/api/FeedApi', {
                headers,
            });

        dispatch(slice.actions.getFeeds(response.data));
    } catch (error) {

    }
}

export const getFeedsItemData = (id: number): AppThunk => async (dispatch) => {
    if (id === null) {
        dispatch(slice.actions.getFeedsItem(null));
        return;
    }

    try {
        const response = await Axios
            .get<any, { data: Feed }>('https://feeds.wishr-click.com/api/FeedApi/' + id, {
                headers,
            });

        let data = null;

        if (response.data) {
            data = response.data;

            if (response.data.source_url) {
                data.source_url = JSON.parse(response.data.source_url);
            }
        }

        dispatch(slice.actions.getFeedsItem(data));
    } catch (error) {
        console.error(error);
    }
}

export const setFeedsDateRange = (range: any[]): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setDateRange(range));
}

export const storeFeeds = (body: Feed): AppThunk => async (dispatch) => {
    const res = await Axios
        .post<any, any>('https://feeds.wishr-click.com/api/FeedApi', body, {
            headers,
        }).catch(err => {
            console.error(err);
            alert(err);
        });

    dispatch(getFeedsData());

    return res;
}

export const storeAsinsFeeds = (body: Feed): AppThunk => async (dispatch) => {
    const res = await Axios
        .post<any, any>('https://feeds.wishr-click.com/api/FeedAsinApi', body, {
            headers,
        }).catch(err => {
            console.error(err);
            alert(err);
        });

    dispatch(getFeedsData());

    return res;
}

export const mutateFeeds = (id: number, body: Feed): AppThunk => async (dispatch) => {
    await Axios
        .put<any, any>('https://feeds.wishr-click.com/api/FeedApi/' + id, body, {
            headers,
        }).catch(err => {
            console.error(err);
            alert(err);
        });

    dispatch(getFeedsData());
}

export const mutateAsinsFeeds = (id: number, body: Feed): AppThunk => async (dispatch) => {
    await Axios
        .put<any, any>('https://feeds.wishr-click.com/api/FeedAsinApi/' + id, body, {
            headers,
        }).catch(err => {
            console.error(err);
            alert(err);
        });

    dispatch(getFeedsData());
}

export const deleteFeed = (id: number): AppThunk => async (dispatch) => {
    const res = await Axios
        .delete<any, any>('https://feeds.wishr-click.com/api/FeedApi/' + id, {
            headers,
        }).catch(err => {
            console.error(err);
            alert(err);
        });

    dispatch(getFeedsData());

    return res;
}

export const getFeedsRulesData = (): AppThunk => async (dispatch) => {
    try {
        const response = await Axios
            .get<any, { data: any }>('https://feeds.wishr-click.com/api/FeedMarkupApi/1', {
                headers,
            });

        const attr = JSON.parse(response.data.attribute.replace(/&quot;/g, '"'));

        const data = {
            Name: attr.Name,
            Price: attr.Price,
            OldPrice: attr.OldPrice,
            ImageUrl: attr.ImageUrl,
            Url: attr.Url,
            last_update: response.data.last_update,
            notes: response.data.notes,
        };

        dispatch(slice.actions.getFeedsRules(data));
    } catch (error) {
        console.error(error);
    }
}

export const mutateFeedsRules = (body: any): AppThunk => async (dispatch) => {

    const resultBody = {
        attribute: {
            Name: body.Name,
            Price: body.Price,
            OldPrice: body.OldPrice,
            ImageUrl: body.ImageUrl,
            Url: body.Url,
        },
        note: body.notes,
    };

    try {
        await Axios
            .put<any, { data: any }>('https://feeds.wishr-click.com/api/FeedMarkupApi/1', resultBody, {
                headers,
            }).then(() => {
                dispatch(getFeedsRulesData());
                alert('Settings Saved');
            }).catch(err => {
                console.error(err);
                alert(err);
            });
    } catch (error) {

    }
}

export const regenerateFeedsWithRules = (body: any): AppThunk => async (dispatch) => {

    const resultBody = {
        attribute: {
            Name: body.Name,
            price: body.price,
            oldPrice: body.oldPrice,
            imageUrl: body.imageUrl,
            url: body.url,
        },
        note: body.notes,
    };

    try {
        await Axios
            .post<any, { data: any }>('https://feeds.wishr-click.com/api/FeedMarkupApi/1', resultBody, {
                headers,
            }).then(() => {
                dispatch(getFeedsRulesData());
            }).catch(err => {
                console.error(err);
            });
    } catch (error) {

    }
}

export const sendFeedRequest = async (body: any) => {
    try {
        const response = await Axios
            .post<any, { data: any }>('https://feeds.wishr-click.com/api/RequestApi', body, {
                headers,
            });

        return response;
    } catch (error) {
        console.log(error);
    }
}

export const sendAsinFeedRequest = async (body: any) => {
    try {
        const response = await Axios
            .post<any, { data: any }>('https://feeds.wishr-click.com/api/ParserUrlApi', body, {
                headers,
            });

        return response;
    } catch (error) {
        console.log(error);
    }
}

export const sendAsinFeedNextRequest = async (body: any) => {
    try {
        const response = await Axios
            .post<any, { data: any }>('https://feeds.wishr-click.com/api/GetItemApi', body, {
                headers,
            });

        return response;

    } catch (error) {
        console.log(error);
    }
}

export const selectFeeds = (state: { feeds: { data: ResultFeeds } }) => state.feeds.data;
export const selectFeedsItem = (state: { feeds: { item: Feed } }) => state.feeds.item;
export const selectFeedsRules = (state: { feeds: { rules: any } }) => state.feeds.rules;
export const selectFeedsDateRange = (state: { feeds: { dateRange: number[]; }; }) => state.feeds.dateRange;

export default slice;
