import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { ResultParserErrors, ParserErrors } from 'src/types/errors';
import Axios from 'axios';

interface ParserErrorsState {
    data: ResultParserErrors;
};

const initialState: ParserErrorsState = {
    data: null
};

const slice = createSlice({
    name: 'parserErrors',
    initialState,
    reducers: {
        getParserErrors(state: ParserErrorsState, action: PayloadAction<ResultParserErrors>) {
            state.data = action.payload;
        }
    }
});

export const reducer = slice.reducer;

export const getParserErrorsData = (): AppThunk => async (dispatch) => {
    
    //   const response = await axios.get<Statistic>('https://api.wishr-click.com/api/statistic');
    
    const response = await Axios.get<any, { data: ParserErrors }>('https://api.wishr-click.com/api/errors', {
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    });

    dispatch(slice.actions.getParserErrors(transformData(response.data)));
};

export const deleteParserErrors = (ids: number[]): AppThunk => async (dispatch) => {
    

    for (const id of ids) {
        await Axios.delete('https://api.wishr-click.com/api/errors', {
            params: { id },
            headers: {
                'Authorization': localStorage.getItem('accessToken')
            }
        });
    }

    dispatch(getParserErrorsData());
};

function transformData(data: ParserErrors): ResultParserErrors {
    const result: any = data;

    return result;
}

export const selectParserErrors = (state: { parserErrors: { data: ResultParserErrors; }; }) => state.parserErrors.data;

export default slice;
