import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Parse, ParseInput, ResultParse } from 'src/types/parse';
import Axios from 'axios';

interface ParseState {
    data: ResultParse;
    parseWith: any;
    duration: number;
};

const initialState: ParseState = {
    data: null,
    parseWith: null,
    duration: null
};

const slice = createSlice({
    name: 'parse',
    initialState,
    reducers: {
        getParse(state: ParseState, action: PayloadAction<ResultParse>) {
            state.data = action.payload;
        },
        getParseDuration(state: ParseState, action: PayloadAction<number>) {
            state.duration = action.payload;
        },
        quickParseWith(state: ParseState, action: PayloadAction<any>) {
            state.parseWith = action.payload;
        }
    }
});

export const reducer = slice.reducer;

export const getParseData = (body: ParseInput): AppThunk => async (dispatch) => {
    await Axios.post<any, { data: Parse }>('https://api.wishr-click.com/api', body, {
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    })
        .then(response => {
            dispatch(slice.actions.getParse(transformData(response.data)));
        }).catch(err => {
            if (err.response) {
                dispatch(slice.actions.getParse(transformData(err.response.data)));
            }
        });
};

export const quickParse = (props): AppThunk => async (dispatch) => {
    dispatch(slice.actions.quickParseWith(props));
};

function transformData(data: Parse): ResultParse {
    const result: any = data;

    return result;
}

export const selectParse = (state: { parse: { data: ResultParse; }; }) => state.parse.data;

export const { getParse, getParseDuration } = slice.actions;

export default slice;
