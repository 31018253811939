import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'

interface CommonState {
  isAddProxyFormOpen: boolean;
  isEditProxyFormOpen: number;
  isEditRetailersFormOpen: number;
}

const initialState: CommonState = {
  isAddProxyFormOpen: false,
  isEditProxyFormOpen: null,
  isEditRetailersFormOpen: null,
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    openAddProxyForm(state: CommonState) {
      state.isAddProxyFormOpen = true;
    },
    closeAddProxyForm(state: CommonState) {
      state.isAddProxyFormOpen = false;
    },
    openEditProxyForm(state: CommonState, action: PayloadAction<number>) {
      state.isEditProxyFormOpen = action.payload;
    },
    closeEditProxyForm(state: CommonState) {
      state.isEditProxyFormOpen = null;
    },
    openEditRetailersForm(state: CommonState, action: PayloadAction<number>) {
      state.isEditRetailersFormOpen = action.payload;
    },
    closeEditRetailersForm(state: CommonState) {
      state.isEditRetailersFormOpen = null;
    },
  }
});

export const reducer = slice.reducer;

export const openAddProxy = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openAddProxyForm());
};

export const closeAddProxy = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeAddProxyForm());
};

export const openEditProxy = (id): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openEditProxyForm(id));
};

export const closeEditProxy = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeEditProxyForm());
};

export const openEditRetailers = (id): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openEditRetailersForm(id));
};

export const closeEditRetailers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeEditRetailersForm());
};

export const selectCommon = (state: { common: CommonState; }) => state.common;

export default slice;
