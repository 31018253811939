import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { Website, ResultWebsites, WebsiteSettings } from 'src/types/websites';

interface WebsitesState {
    data: {
        rows: ResultWebsites;
        count: number;
    };
    item: Website;
    settings: WebsiteSettings;
};

const initialState: WebsitesState = {
    data: {
        rows: [],
        count: 0,
    },
    item: null,
    settings: [],
};

const slice = createSlice({
    name: 'websites',
    initialState,
    reducers: {
        getWebsites(state: WebsitesState, action: PayloadAction<{
            rows: ResultWebsites;
            count: number;
        }>) {
            state.data = action.payload;
        },
        getWebsitesItem(state: WebsitesState, action: PayloadAction<Website>) {
            state.item = action.payload;
        },
        getWebsitesSettings(state: WebsitesState, action: PayloadAction<any>) {
            state.settings = action.payload;
        },
    }
});

export const getStatusName = (status: number): string => {
    switch (status) {
        case 1:
            return 'Request Sent';

        case 2:
            return 'Approved';

        case 3:
            return 'Rejected';

        case 4:
            return 'Postponed';

        default:
            return '';
    }
}

export const getStatusColors = (status: number): string => {
    switch (status) {
        case 1:
            return '#d1d100';

        case 2:
            return '#00eb00';

        case 3:
            return '#fb5d5d';

        case 4:
            return '#6a6a6a';

        default:
            return '';
    }
}

export const reducer = slice.reducer;

export const getWebsitesData = (page?: number, searchField?: string, searchString?: string, sortField?: string, sortDirection?: string): AppThunk => async (dispatch) => {
    try {
        const limit = 100;
        const offset = page ? (page - 1) * limit : 0;
        const sortPair = (sortField && sortDirection) ? `, sortPair:{
            sortField: ${sortField},
            direction: ${sortDirection.toUpperCase()}
        }` : '';

        const response = await fetch(process.env.REACT_APP_WEBSITES_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                "query": `query {
                    getShops(
                        pagination:{
                            limit: ${limit},
                            offset: ${offset},
                        },
                        searchPair:{
                            searchField: ${searchField},
                            searchString: "${searchString}"
                        }
                        ${sortPair}
                    ) {
                        rows {
                            id
                            name
                            affiliateNetwork
                            categories
                            domainUrl
                            shopId
                            shopImgUrl
                            countries
                            isActive
                            promotionCount
                        }
                        count
                    }
                }`,
            }),
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        const result = await response.json();

        dispatch(slice.actions.getWebsites({
            rows: result.data?.getShops?.rows,
            count: result.data?.getShops?.count,
        }));
    } catch (error) {
        console.error(error);
    }
}

export const mutateWebsiteStatus = (id: number, isActive: boolean, opt?: { page?: number, searchField?: string, searchString?: string }): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_WEBSITES_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                "query": `mutation {
                    updateShopStatus(id: ${id}, isActive: ${isActive})
                }`,
            }),
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        dispatch(getWebsitesData(opt.page, opt.searchField, opt.searchString));
    } catch (error) {
        console.error(error);
    }
}

export const getWebsitesSettingsData = (): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_WEBSITES_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                "query": `query{
                    getLocaleRulesList{
                      id
                      localeName
                      shopRelatedLocalesNames
                    }
                }`,
            }),
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        const result = await response.json();

        dispatch(slice.actions.getWebsitesSettings(result.data?.getLocaleRulesList));

    } catch (error) {
        console.error(error);
    }
}

const queryfy = obj => {
    if (typeof obj === 'number') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const props = obj.map(value => `${queryfy(value)}`).join(',');
        return `[${props}]`;
    }

    if (typeof obj === 'object') {
        const props = Object.keys(obj)
            .map(key => `${key}:${queryfy(obj[key])}`)
            .join(',');
        return `{${props}}`;
    }

    return JSON.stringify(obj);
}

export const mutateWebsitesSettings = (body: any[]): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_WEBSITES_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                "query": `mutation {
                localeRules(input: {
                    rules: ${queryfy(body)}
                })
            }`,
            }),
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        dispatch(getWebsitesSettingsData());

    } catch (error) {
        console.error(error);
    }
}

export const mutateWebsitesUploads = (body: any): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/v1/import/import-shops`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body,
        });

        const result = await response.json();

        return result;

    } catch (error) {
        console.error(error);
    }
}

export const selectWebsites = (state: {
    websites: {
        data: {
            rows: ResultWebsites;
            count: number;
        }
    }
}) => state.websites.data;

export const selectWebsitesSettings = (state: { websites: { settings: WebsiteSettings } }) => state.websites.settings;

export default slice;
