import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as statisticReducer } from 'src/slices/statistic';
import { reducer as parserErrorsReducer } from 'src/slices/errors';
import { reducer as parseReducer } from 'src/slices/parse';
import { reducer as proxyReducer } from 'src/slices/proxy';
import { reducer as retailersReducer } from 'src/slices/retailers';
import { reducer as feedsReducer } from 'src/slices/feeds';
import { reducer as websitesReducer } from 'src/slices/websites';
import { reducer as promosReducer } from 'src/slices/promos';
import { reducer as commonReducer } from 'src/slices/common';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  statistic: statisticReducer,
  parserErrors: parserErrorsReducer,
  parse: parseReducer,
  proxy: proxyReducer,
  retailers: retailersReducer,
  feeds: feedsReducer,
  websites: websitesReducer,
  promos: promosReducer,
  common: commonReducer,
});

export default rootReducer;
