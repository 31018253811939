import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { ResultStatistic, Statistic } from 'src/types/statistic';
import Axios from 'axios';

interface StatisticState {
    data: ResultStatistic;
    timeRange: number[];
};

const date = new Date();
date.setMonth(date.getMonth() - 1);
const startFrom = date.getTime();
const startTo = new Date().getTime();

const initialState: StatisticState = {
    data: null,
    timeRange: [startFrom, startTo]
};

const slice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {
        getStatistic(state: StatisticState, action: PayloadAction<ResultStatistic>) {
            state.data = action.payload;
        },
        statisticTimeRange(state: StatisticState, action: PayloadAction<any[]>) {
            state.timeRange = action.payload;
        }
    }
});

export const reducer = slice.reducer;

export const getStatisticData = (): AppThunk => async (dispatch) => {

    //   const response = await axios.get<Statistic>('https://api.wishr-click.com/api/statistic');

    const response = await Axios.get<any, { data: Statistic }>('https://api.wishr-click.com/api/statistic', {
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    });

    dispatch(slice.actions.getStatistic(transformData(response.data)));
};

export const setStatisticTimeRange = (range: any[]): AppThunk => async (dispatch) => {
    dispatch(slice.actions.statisticTimeRange(range));
};

function transformData(data: Statistic): ResultStatistic {
    const result: ResultStatistic = {
        rawData: data,
        today: {
            requestSum: 0,
            errorSum: 0,
            date: ''
        },
        alltime: {
            requestSum: 0,
            errorSum: 0
        },
        graph: [],
        dataArr: [],
        firstDate: ''
    };

    let reqSumAlltime = 0,
        errSumAlltime = 0;

    let reqSumToday = 0,
        errSumToday = 0;

    const firstKey = Object.keys(data)[0];
    const lastKey = Object.keys(data).slice(-1)[0];

    const getTimestamp = function (date) {
        const dt = date.split('-');
        dt.reverse();
        return new Date(dt.join('-')).getTime();
    }

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const dataItem = data[key];
            const graphItem = {
                date: key,
                timestamp: getTimestamp(key),
                requestSum: 0,
                errorSum: 0
            };

            let errCount = 0;

            for (const item of dataItem) {
                if (item.domain === 'Too many requests') {
                    graphItem.errorSum += item.count;

                    errSumAlltime += item.count;

                    errCount = item.count;

                    if (key === lastKey) {
                        errSumToday += item.count;
                    }
                } else {
                    graphItem.requestSum += item.count;

                    reqSumAlltime += item.count;

                    if (key === lastKey) {
                        reqSumToday += item.count;
                    }
                }
            }

            result.graph.push(graphItem);
            result.dataArr.push({
                date: key,
                timestamp: getTimestamp(key),
                errCount,
                items: dataItem.filter(item => item.domain !== 'Too many requests')
            });
        }
    }

    result.graph.sort((a,b) => a.timestamp - b.timestamp);

    result.firstDate = result.graph[0].date;
    result.alltime.requestSum = reqSumAlltime;
    result.alltime.errorSum = errSumAlltime;
    result.today.requestSum = reqSumToday;
    result.today.errorSum = errSumToday;
    result.today.date = lastKey;

    return result;
}

export const selectStatistic = (state: { statistic: { data: ResultStatistic; }; }) => state.statistic.data;
export const selectStatisticTimeRange = (state: { statistic: { timeRange: any[]; }; }) => state.statistic.timeRange;

export default slice;
