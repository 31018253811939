import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { ResultPromos } from 'src/types/promos';

interface PromosState {
    data: {
        rows: ResultPromos;
        count: number;
    };
};

const initialState: PromosState = {
    data: {
        rows: [],
        count: 0,
    },
};

const slice = createSlice({
    name: 'promos',
    initialState,
    reducers: {
        getPromos(state: PromosState, action: PayloadAction<{
            rows: ResultPromos;
            count: number;
        }>) {
            state.data = action.payload;
        },
    }
});

export const reducer = slice.reducer;

export const getPromosData = (page?: number): AppThunk => async (dispatch) => {
    try {
        const limit = 100;
        const offset = page ? (page - 1) * limit : 0;

        const response = await fetch(process.env.REACT_APP_PROMOS_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                "query": `query {
                    getPromotionStatistic(
                        pagination:{
                            limit: ${limit},
                            offset: ${offset},
                        },
                    ) {
                        rows {
                            totalCount
                            lastUpdate
                            affiliateNetwork
                        }
                        count
                    }
                }`,
            }),
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        const result = await response.json();

        dispatch(slice.actions.getPromos({
            rows: result.data?.getPromotionStatistic?.rows,
            count: result.data?.getPromotionStatistic?.count,
        }));
    } catch (error) {
        console.error(error);
    }
}

export const updatePromos = (network: string): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_UPDATE_PROMOS_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: `{"query":"query ExampleQuery($pass: String!, $affiliateNetrows: [AffiliateNetworks!]!) { triggerDemoPromotionsUpload(pass: $pass, affiliateNetrows: $affiliateNetrows) }","variables":{"pass":"promo_upload", "affiliateNetrows":["${network}"]}}`,
        });

        if (!response.ok) {
            throw new Error('FAILED_TO_FETCH_DATA');
        }

        const result = await response.json();

        if (result.data?.triggerDemoPromotionsUpload) {
            dispatch(getPromosData());
        }

        return true;

    } catch (error) {
        console.error(error);
    }
}

export const selectPromos = (state: {
    promos: {
        data: {
            rows: ResultPromos;
            count: number;
        }
    }
}) => state.promos.data;

export default slice;
