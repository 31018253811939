import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/parse',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/parse/v1'))
  },
  {
    exact: true,
    path: '/proxy',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/proxy/v1'))
  },
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/statistic/v1'))
  },
  {
    exact: true,
    path: '/errors',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/parserErrors/v1'))
  },
  {
    exact: true,
    path: '/retailers',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/retailers'))
  },
  {
    exact: true,
    path: '/feeds',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds'))
  },
  {
    exact: true,
    path: '/feeds/create',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds/item/Item'))
  },
  {
    exact: true,
    path: '/feeds/create-asin',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds/item-asin/Item'))
  },
  {
    exact: true,
    path: '/feeds/:id/edit',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds/item/Item'))
  },
  {
    exact: true,
    path: '/feeds/:id/edit-asin',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds/item-asin/Item'))
  },
  {
    exact: true,
    path: '/feeds/rules',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/feeds/rules'))
  },
  {
    exact: true,
    path: '/websites',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/websites'))
  },
  {
    exact: true,
    path: '/websites/settings',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/websites/settings'))
  },
  {
    exact: true,
    path: '/websites/upload-list',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/websites/upload'))
  },
  {
    exact: true,
    path: '/promos',
    // guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/promos'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
];

export default routes;
